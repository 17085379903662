import React, {useEffect, useState} from 'react'
import {Badge, Button, Collapse, Modal, Space} from 'antd';
import {withTranslation} from "react-i18next";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {errorHandle} from "../../../../lib/BuilderUtils";
import NoDataBlock from "../../../../components/common/NoDataBlock";
import axios from 'axios'
import styled from "styled-components";
import _ from "lodash";
import Icon from "@mdi/react";
import {mdiBullhornOutline} from "@mdi/js";

const { Panel } = Collapse;

const NoticeDiv = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 15px;
  margin-bottom:10px;
  width: 100%;
  border: 2px dashed #eee;
  line-height: 1.5;
  color: ${props => (props.white ? '#fff' : '#aaa')};
`;

const Notice = ({t}) => {

    const topNoticeKey = 'notice-0';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [noticeList, setNoticeList] = useState(null);
    const [notReadNotices, setNotReadNotices] = useState([]);

    const showModal = () => {
        setReadNotice(topNoticeKey);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const NoticeHeader = (notice) => {
        return (
            <div>
                <div className='float-right'>{notice.date}</div>
                <div className='pull-left'>{notice.title}</div>
            </div>
        )
    }

    function onChange(key) {
        if(key)
            setReadNotice(key);
    };

    const setReadNotice = (key) => {
        const idx = parseInt(key.substring(key.indexOf("-") + 1));
        const noticeId = noticeList[idx]?.id || -1;

        (async () => {
            //POST Request
            try {
                //REST API Request
                const {status} = await axios({
                    url:`/api/notice`,
                    method:'post',
                    params:{noticeId}
                });
                if(status === httpStatus.ok) {
                    let cloneNotReadNotices = _.cloneDeep(notReadNotices.filter(n => n !== noticeId));
                    setNotReadNotices(cloneNotReadNotices);
                }
            } catch(error) {
                //Error Exception
                errorHandle(error);
            }
        })();
    }

    //Init
    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/notice`);
                if (status === httpStatus.ok) {
                    setNoticeList(data?.noticeList);
                    setNotReadNotices(data?.notReadNoticeIds);
                }
            } catch(error) {
                //Error Exception
                errorHandle(error);
            }
        })()
    }, []);

    return (
        <>
            <Space size="large">
                <Badge size="small" count={notReadNotices.length} overflowCount={5}>
                    <Button onClick={showModal} shape="circle" type="text" icon={<Icon path={mdiBullhornOutline} size={1}></Icon>} />
                    <Modal title={t('index.title.notice')} open={isModalOpen} siz width={"70%"} onCancel={handleCancel} footer={[]}>
                        {
                            noticeList === null ?
                            <NoticeDiv style={{borderColor: "transparent"}}>
                                <NoDataBlock />
                            </NoticeDiv> :
                            <Collapse accordion defaultActiveKey={topNoticeKey} onChange={onChange}>
                                {
                                    noticeList?.map(function (notice, idx) {
                                        return (
                                            <Panel header={NoticeHeader(notice)} key={`notice-${idx}`}>
                                                <div style={{width:"100%", overflow: "auto"}}
                                                     dangerouslySetInnerHTML={{__html:notice.content}}></div>
                                            </Panel>
                                        )
                                    })
                                }
                            </Collapse>
                        }
                    </Modal>
                </Badge>
            </Space>
        </>
    );
}

export default withTranslation()(Notice);