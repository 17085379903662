import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Spin,
    Switch,
    TimePicker,
    Tooltip,
    Typography
} from 'antd';

import React, {useEffect, useState} from "react";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-noconflict/snippets/sqlserver";
import "ace-builds/src-min-noconflict/ext-searchbox";
import dayjs from 'dayjs';
import {useSelector} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons';
import _ from "lodash";
import {useTranslation} from "react-i18next";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const format = 'HH:mm';

const endTimeAlarmMap = {
    HALF_HOUR:0.5,
    ONE_HOUR:1,
    ONE_HALF_HOUR:1.5,
    TWO_HOUR:2,
    TWO_HALF_HOUR:2.5,
    THREE_HOUR:3,
    THREE_HALF_HOUR:3.5,
    FOUR_HOUR:4,
    FOUR_HALF_HOUR:4.5,
    FIVE_HOUR:5,
    FIVE_HALF_HOUR:5.5,
    SIX_HOUR:6,
}

const ProScheduleDef = ({metaData, oid, studyEventOID, formOID, scheduleDef = null, onFinish, showModal, closeModal, isProd}) => {
    const [form] = Form.useForm();
    const initialValue = {
        oid:`PRO.${studyEventOID}.${formOID}`,
        studyEventOID:studyEventOID,
        formOID:formOID,
        sourceStudyEventOID:'',
        numberOfTimes:1,
        interval:0,
        delayDays:0,
        preWindow:0,
        preWindowTime:0,
        usePreWindowAlarm:false,
        postWindow:0,
        postWindowTime:0,
        endTimeAlarms:[],
        time:dayjs('00:00', format)
    };

    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const metaDataStore = useSelector((store) => store.metaDataStore);
    const [studyEventOptions, setStudyEventOptions] = useState(null);
    const [endTimeAlarmOptions, setEndTimeAlarmOptions] = useState(null);
    // const [proScheduleDef, setProScheduleDef] = useState(initialValue);
    // setEditMode(false);

    useEffect(() => {
        console.log('proScheduleDef > useEffect => ', oid, studyEventOID, formOID);
        if(metaData.eproScheduleMap.has(oid)) {
            const schedule = metaData.eproScheduleMap.get(oid);
            schedule.time = dayjs(schedule.time, format);
            form.setFieldsValue(schedule);
        } else {
            initialValue.studyEventOID = studyEventOID;
            initialValue.formOID = formOID;
            initialValue.oid = oid;

            console.log('initialValue => ', initialValue);
            form.setFieldsValue(initialValue);
        }

        let _studyEventOIDs = [];

        metaDataStore.study.metaDataVersion[0].protocol.studyEventGroupRef.forEach(g => {
            let oids = metaData.studyEventGroup.get(g.studyEventGroupOID).studyEventRef
                .filter(s => metaData.studyEvent.get(s.studyEventOID)?.type === 'SCHEDULED')
                .map(s => s.studyEventOID);
            if(oids.length > 0) {
                _studyEventOIDs = _studyEventOIDs.concat(oids);
            }
        });

        let _studyEventOptions = [];
        _studyEventOIDs.forEach(eventOID => {
            _studyEventOptions.push({value:eventOID, label:metaData.studyEvent.get(eventOID).name});
        });

        setStudyEventOptions(_studyEventOptions);

        const endTimeAlarmOptionType = metaDataStore.endTimeAlarmOptionType;
        //End Time Alarms Option Setting
        setEndTimeAlarmOptions([...endTimeAlarmOptionType]);

    }, [oid, studyEventOID, formOID]);

    useEffect(() => {
        setLoading(true);
        setEditMode(true);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const onChangeTime = _.debounce((value, pathName) => {
        //입력값 value에서 0.5로 나누어떨어지는 값과 제일 근사치인 값을 선택
        if(pathName === 'time') {
            const mValue= dayjs(value).format("mm");
            const settingMinute = mValue < 30 ? 0 : 30;
            const newValue = dayjs(value).set("minute", settingMinute);

            form.setFieldValue(pathName, newValue);

        } else {
            const [dValue, fValue] = value.split(".");
            if (fValue !== undefined && fValue !== '') {
                const floatValue = Number(fValue) < 5 ? 0 : 5;
                form.setFieldValue(pathName, `${dValue}.${floatValue}`);
            }
        }
    }, 50);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                // Submit values
                values.time = dayjs(values.time).format(format);
                onFinish(values);
                closeModal();
            })
            .catch((errorInfo) => {});
    };

    return (

        <Modal
            title="ePRO Schedule Editor"
            centered
            maskClosable={false}
            keyboard={false}
            open={showModal}
            okText={'Save'}
            destroyOnClose={true}
            onCancel={() => closeModal()}
            width={400}
            footer={[
                (!isProd) && <Button type={"primary"} disabled={isProd} onClick={() => handleFormSubmit()}><i className={'fa fa-check'}></i>&nbsp;&nbsp;Save</Button>,
                <Button type={'link'} onClick={() => closeModal()}><i className={'fa fa-times'}></i>&nbsp;&nbsp;Close</Button>,
            ]}
            afterClose={() => {setEditMode(false);closeModal();}}
        >
            <Spin spinning={loading} tip={'Loading...'} size={'large'}>
                <Form layout={"vertical"} form={form} name="control-hooks"
                      onFinish={onFinish} size={'middle'}>
                    <Form.Item className={'mb-0'} name="oid" label="OID" hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="studyEventOID" label="StudyEventOID" hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item className={'mb-0'} name="formOID" label="formOID" hidden={true}>
                        <Input readOnly={true}/>
                    </Form.Item>
                    <Row gutter={[10,10]}>
                        <Col span={24}>
                            <Form.Item className={'mb-0'} name="sourceStudyEventOID" label="Baseline Visit"
                                       rules={[{required: true, message: "Required."}]}>
                                <Select options={studyEventOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className={'mb-0'} name="delayDays" label="After a few days (D)"
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className={'mb-0'} name="numberOfTimes" label="Number of Times"
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={1} max={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className={'mb-0'} name="time" label="Time (hh:mm)" rules={[{required: true, message: "Required."}]}>
                                <TimePicker format={format} minuteStep={30} onChange={(value) => onChangeTime(value, "time")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className={'mb-0'} name="interval" label="Interval (D)"
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                        </Col>
                        <Col span={24} className={'mt-1 mb-1'}>
                            <Typography className={'d-inline-block font-size-16 font-weight-bold'}>
                                Pre-Window
                            </Typography>
                            <span>&nbsp;&nbsp;(Alarm&nbsp;:&nbsp;</span>
                            <Form.Item name="usePreWindowAlarm" valuePropName={"checked"} noStyle={true}>
                                <Switch checkedChildren={'YES'} unCheckedChildren={'NO'} size={'small'} />
                            </Form.Item>
                            <span>&nbsp;)</span>
                        </Col>
                        <Col>
                            <Form.Item className={'mb-0'} name="preWindow" label="Day"
                                       layout={'horizontal'}
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item className={'mb-0'} name="preWindowTime" label="Hour"
                                       layout={'horizontal'}
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={0} max={23.5} onInput={(value) => onChangeTime(value, 'preWindowTime')} step={0.5} />
                            </Form.Item>
                        </Col>

                        <Col span={24} className={'mt-1 mb-1'}>
                            <Typography className={'font-size-16 font-weight-bold'}>Post-Window</Typography>
                        </Col>
                        <Col>
                            <Form.Item className={'mb-0'} name="postWindow" label="Day"
                                       layout={'horizontal'}
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item className={'mb-0'} name="postWindowTime" label="Hour"
                                       layout={'horizontal'}
                                       rules={[{ required: true, message:'Required.' }]}>
                                <InputNumber min={0} max={23.5} onInput={(value) => onChangeTime(value, 'postWindowTime')} step={0.5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className={'mb-0'} name="endTimeAlarms" label={
                        <>
                            <span className={'mr-2'}>Before End Time Alarms</span>
                            <Tooltip title={
                                <>
                                    <div className={'mb-2 font-size-16'}>Before End Time Alarms</div>
                                    <span className={'d-block'} dangerouslySetInnerHTML={{__html:t('message.pro-schedule.before-end-time-alarms.description')}}></span>
                                </>}>
                                <i className='text-muted fa fa-exclamation-circle' />
                            </Tooltip>
                        </>
                    } rules={[
                        {
                            validator:(_, endTimeAlarmList) => {
                                let alarmMaxTimeValue = 0;

                                endTimeAlarmList.forEach((endTimeAlarm) => {
                                    const timeValue = endTimeAlarmMap[endTimeAlarm];
                                    if(alarmMaxTimeValue < timeValue) {
                                        alarmMaxTimeValue = timeValue;
                                    }
                                });

                                const postWindowTime = form.getFieldValue('postWindowTime');
                                const postWindow = form.getFieldValue('postWindow');
                                const timeValue = postWindow * 24 + postWindowTime;

                                // console.log(alarmMaxTimeValue + " > " + timeValue);

                                if(timeValue >= 0.5 && alarmMaxTimeValue+1 > timeValue) {
                                    return Promise.reject(new Error(t('message.error.alarm.setting')));
                                }

                                return Promise.resolve();
                            }
                        }
                    ]}>
                        <Select mode={"multiple"} options={endTimeAlarmOptions} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default ProScheduleDef;
