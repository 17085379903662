import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Popconfirm, Space, Switch, Table, Tag} from 'antd';
import {isEmpty} from "../../../../lib/StringUtils";
import {errorHandle} from "../../../../lib/BuilderUtils";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {useNavigate, useParams} from "react-router-dom";
import {CardContent, CardHeader, PrimaryCard} from "../../../../pages/PageStyleBox";
import {RightOutlined} from "@ant-design/icons";
import reactCSS from 'reactcss';
import {SketchPicker} from 'react-color';
import {useTranslation} from "react-i18next";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const SponsorSubjectStatusContainer = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const pattern = /^([A-Za-z]+)([0-9]+)?(([_])?([A-Za-z0-9_]+))*$/i

    const [form] = Form.useForm();
    const [statusList, setStatusList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [oid, setOid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
    });
    const [colorCode, setColorCode] = useState(null);

    useEffect( () => {
        (async() => {
            //GET Request
            try {
                //REST API Request
                const {data, status} = await axios.get(`/api/builder/sponsors/${params.sponsorId}/subject-status`);

                if (status === httpStatus.ok) {
                    //State Setting
                    setStatusList(data.statusList);
                    form.setFieldsValue({statusList: data.statusList});
                    setLoading(false);
                }
            } catch (error) {
                //Error Exception
                errorHandle(error, navigate, "/sponsors");
            }
        })()
    }, []);
//

    const fnEdit = ({oid}) => {
        setOid(oid);
        setEditMode(isEmpty(oid));
        if(!isEmpty(oid)) {
            const subjectStatusObj = statusList.find(s => s.oid === oid);
            form.setFieldsValue(subjectStatusObj);
            setColorCode(subjectStatusObj.color);
        }
        setShowModal(true);
    }

    const onFinish = async (subjectStatusDef) => {
        setLoading(true);

        try {
            const {status, data} = await axios({
                url:`/api/builder/sponsors/${params.sponsorId}/subject-status`,
                method:'post',
                data: subjectStatusDef,
            });

            if(status === httpStatus.ok) {
                message.success(data?.message);
                setStatusList(data?.statusList);
                setOid(null);
                setShowModal(false);
            }
        } catch(e) {
            errorHandle(e);
        }
        form.resetFields();
        setLoading(false);
    };

    const onFinishFailed = (error) => {
        console.log(error, "color:red;");
    }

    const deleteStatus = async (oid) => {
        setLoading(true);
        try {
            const {status, data} = await axios({
                url:`/api/builder/sponsors/${params.sponsorId}/subject-status`,
                method:"delete",
                params:{oid},
            })

            if(status === httpStatus.ok) {
                message.success(data?.message);
                setStatusList(data?.statusList);
            }
        } catch(e) {
            errorHandle(e);
        }
        setLoading(false);
    }

    const closeModal = () => {
        setShowModal(false);
        setOid(null);
        form.resetFields();
    }

    const onChangeColor = color => {
        setColor(color.rgb);
        setColorCode(color.hex);
        form.setFieldsValue({"color":color.hex});
    }

    const columns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Display Order',
            dataIndex: 'displayOrder',
            key: 'displayOrder',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <Tag color={record.color}>{text}</Tag>
        },
        // {
        //     title: 'Color',
        //     dataIndex: 'color',
        //     key: 'color',
        //     render:text => <span style={{backgroundColor:text,padding:3,fontSize:12}}>{text}</span>
        // },
        {
            title: 'Disable the next visit.',
            dataIndex: 'disabledNextVisit',
            key: 'disabledNextVisit',
            render:text => <Switch checked={text} disabled={true}></Switch>
        },
        {
            title: 'Exclude Subject Count',
            dataIndex: 'excludeSubjectCount',
            key: 'excludeSubjectCount',
            render:text => <Switch checked={text} disabled={true}></Switch>
        },
        {
            title: 'Action',
            key: 'action',
            render:(text, record) => (
                <Space size="middle">
                    <a onClick={() => fnEdit({oid:record.oid})}><i className={'fa fa-search'}></i></a>
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        onConfirm={() => deleteStatus(record.oid)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a href="#"><i className={'fa fa-trash-o'}></i></a>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                // background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
                background: `${ colorCode }`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    /**
     * 해당 oid가
     * @param val
     * @returns {string|boolean}
     */
    const contains = (val) => {
        if(!isEmpty(oid)) return true;
        if(val===undefined) return true;
        const checkOid = val.toUpperCase();
        const returnValue = !statusList.some(status => status?.oid?.toUpperCase() === checkOid);
        if(!returnValue) { return checkOid + t('sponsor.already.in.use'); }
        return returnValue;
    }

    const handleChange = (e) => {
        form.setFieldsValue('oid', e.target.value.toUpperCase());
    }

    return (
        <PrimaryCard>
            <CardHeader>
                <div style={{float: 'right'}}>
                    <Button type={"primary"} onClick={() => fnEdit({oid:""})}><i className={'fa fa-plus mr-2'} />New</Button>
                </div>
                <h3><RightOutlined className={'mr-2'} />Subject Status</h3>
            </CardHeader>
            <CardContent>
                <Table dataSource={statusList} bordered size={'small'} loading={loading} columns={columns} />
                {/*<EditCheckDef metaData={metaData} oid={oid} onFinish={onFinish} showModal={showModal} closeModal={closeModal}/>*/}
                <Modal
                    title="Subject Status"
                    centered
                    maskClosable={false}
                    keyboard={false}
                    visible={showModal}
                    okText={'Save'}
                    onOk={() => form.submit()}
                    onCancel={() => closeModal()}
                    footer={[
                        !editMode && <Button type={'link'} onClick={() => setEditMode(true)}><i className={'fa fa-pencil mr-2'} />Edit</Button>,
                        editMode && <Button type={"primary"} disabled={!editMode} onClick={() => form.submit()}><i className={'fa fa-check mr-2'} />Save</Button>,
                        <Button type={'link'} onClick={() => closeModal()}><i className={'fa fa-times mr-2'} />Close</Button>,
                    ]}
                >
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} onFinishFailed={onFinishFailed} size={'middle'}>
                        <Form.Item name={'id'} hidden={true}><Input /></Form.Item>
                        <Form.Item name={'oid'} label={<span className='text-primary'>OID</span>}
                                   rules={[
                                       {required: true, message: t('message.warning.required-entry')},
                                       {max: 50, message: t('message.can.enter.up.to.fifty.char')},
                                       {pattern: pattern, message: t('message.format.is.not.valid')},
                                       ({ getFieldValue }) => ({
                                           validator(_, value) {
                                               if (isEmpty(value)) { return Promise.resolve(); }
                                               const result = contains(value);
                                               if (result !== true) {return Promise.reject(new Error(result));}
                                               else {return Promise.resolve();}
                                           }
                                       })]}
                                   required={false}>
                            <Input readOnly={oid !== ""} onChange={handleChange} style={{imeMode: 'disabled'}} />
                        </Form.Item>
                        {/*</Form.Item>*/}
                        <Form.Item className={'mb-0'} name="status" label="Status" rules={[{ required: true, message:t('message.pls.enter.status')}]}>
                            <Input disabled={!editMode}/>
                        </Form.Item>
                        <Form.Item className={'mb-0'} name="displayOrder" label="Display Order" rules={[{ required: true, message:t('message.pls.enter.display.order')}]}>
                            <Input type={"number"} disabled={!editMode}/>
                        </Form.Item>
                        <Form.Item className={'mb-0'} name="color" label="Color" rules={[{ required: true, message:t('message.pls.enter.color')}]}>
                            <Input type={"text"} hidden={true} disabled={!editMode}/>
                            <div>
                                <div style={ styles.swatch } onClick={ () => editMode ? setDisplayColorPicker(true) : null}>
                                    <div style={ styles.color } />
                                </div>
                                { displayColorPicker ? <div style={ styles.popover }>
                                    <div style={ styles.cover } onClick={ () => setDisplayColorPicker(false) }/>
                                    <SketchPicker color={ color } onChange={ onChangeColor } presetColors={
                                        ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3',
                                            '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000',
                                            '#EB144C', '#8BC34A', '#3F51B5', '#F47373']} />
                                </div> : null }
                            </div>
                        </Form.Item>
                        <Form.Item className={'mb-0'} name="disabledNextVisit" label="Disable the next visit." valuePropName={"checked"} initialValue={false}>
                            <Switch disabled={!editMode}/>
                        </Form.Item>
                        <Form.Item className={'mb-0'} name="excludeSubjectCount" label="Exclude Subject Count." valuePropName={"checked"} initialValue={false}>
                            <Switch disabled={!editMode}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </CardContent>
        </PrimaryCard>
    )
}

export default SponsorSubjectStatusContainer;
