import classNames from "classnames";
import MenuClassic from "../../@vb/sscomponents/MenuClassic";
import {Layout} from "antd";
import TopBar from "../../@vb/sscomponents/TopBar";
import Footer from "../../@vb/sscomponents/Footer";
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {isEmpty} from "../../lib/StringUtils";
import axios from "axios";
import {httpStatus} from "../../lib/createRequestSaga";
import {errorHandle} from "../../lib/BuilderUtils";

const MainLayout = ({children, user}) => {

    const location = useLocation();
    const [edcStudyInfo, setEdcStudyInfo] = useState(null);
    const params = useParams();

    useEffect(() => {
        if(!isEmpty(params.sid) && params.sid !== 'edit' && params.sid !== 'manage-access') {
            //sid가 초기값이거나 현재 sid가 변경되었으면,
            (async () => {
                if (edcStudyInfo?.oid !== params.sid) {
                    try {
                        const {status, data} = await axios({
                            url: '/api/builder/edcStudyInfo',
                            params: {sid: params.sid},
                            method: 'get'
                        });

                        if (status === httpStatus.ok) {
                            setEdcStudyInfo(data);
                        }
                    } catch (e) {
                        errorHandle(e);
                    }
                }
            })();
        } else if(params?.sid == null) {
            setEdcStudyInfo(null);
        }
    }, [location.pathname])

    return (
        <div className='vb__layout__grayBackground'>
            <Layout
                className={classNames('vb__layout', {
                    vb__layout__contentMaxWidth: false,
                    vb__layout__appMaxWidth: false,
                    vb__layout__squaredBorders: true,
                    vb__layout__cardsShadow: true,
                    vb__layout__borderless: false,
                })}
            >
                <MenuClassic menuLayoutType={'top'} edcStudyInfo={edcStudyInfo}/>
                <Layout.Header
                    className={classNames('vb__layout__header', {
                        vb__layout__fixedHeader: false,
                        vb__layout__headerGray: false,
                        vb__layout__separatedHeader: false,
                    })}
                >
                    <TopBar user={user} edcStudyInfo={edcStudyInfo}/>
                </Layout.Header>
                {/*<Breadcrumbs />*/}
                <Layout.Content className="vb__layout__content p-2 pt-4">
                    {children}
                </Layout.Content>
                <Layout.Footer>
                    <Footer/>
                </Layout.Footer>
            </Layout>
        </div>
    )

};

export default MainLayout;