import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import {useSelector} from "react-redux";
import api from '../../lib/api/metadata';
import {Badge, Button, Card, Col, Descriptions, Form, message, Row, Space, Table, Tabs, Tag, Typography} from "antd";
import axios from "axios";
import dayjs from 'dayjs'
import {
    AdverseEvent,
    AdverseEventDiff,
    CodeList,
    CodeListDiff,
    EditCheck,
    EditCheckDiff,
    FormDef,
    FormDefDiff,
    ItemDef,
    ItemDefDiff,
    ItemGroupDef,
    ItemGroupDefDiff, ProSchedule, ProScheduleDiff,
    RandomNumber,
    RandomNumberDiff, SAEMapDef, SAEMapDefDiff,
    ScreeningNumber,
    ScreeningNumberDiff,
    StudyEventDef,
    StudyEventDefDiff,
    StudyEventGroupDef,
    StudyEventGroupDefDiff,
    SubjectField,
    SubjectFieldDiff,
    SubjectStatus,
    SubjectStatusDiff,
    ValueList,
    ValueListDiff,
    VisitWindow,
    VisitWindowDiff
} from "./ComponentDiff";
import {isEnvProduction, toMetaData} from "../../lib/ODMUtils";
import {StickyDiv} from "../build-tool/StyleBox";
import {errorHandle} from "../../lib/BuilderUtils";
import {httpStatus} from "../../lib/createRequestSaga";
import FormTextArea from "../../components/common/odm/ant-form/FormTextArea";
import {isEmpty} from "../../lib/StringUtils";
import Swal from "sweetalert2";
import {withTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import PublishRecordErrorModal from "./modal/PublishRecordErrorModal";

const { TabPane } = Tabs;


const PublishContainer = ( {t} ) => {
    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const params = useParams();
    const isProd = isEnvProduction(metaDataStore);
    const [changeLog, setChangeLog] = useState({changeCount:0,newVersionName:'-',oldVersionName:''});
    const [publishRecords, setPublishRecords] = useState(null);
    const metaData = toMetaData(metaDataStore.study);
    const [form] = Form.useForm();
    const v = metaDataStore.study.metaDataVersion[0];
    const protocolCount = v.protocol !== null && v.protocol !== undefined && Array.isArray(v.protocol.studyEventGroupRef) && v.protocol.studyEventGroupRef.length > 0;
    const protocol = (protocolCount > 0);
    const [recordErrorVisible, setRecordErrorVisible] = useState(false);
    const [recordErrorMessages, setRecordErrorMessages] = useState(null);

    const [emailNoticeList, setEmailNoticeList] = useState(null);
    const [checkNotificationECS, setCheckNotificationECS] = useState(null);

    const [loading, setLoading] = useState(true);

    const studyEventGroupOID = protocol ? v.protocol.studyEventGroupRef[0].studyEventGroupOID : null;
    // console.log(studyEventGroupOID);

    const findFirstStudyEventOID = (metaData, studyEventGroupOID) => {
        if(metaData.studyEventGroup.has(studyEventGroupOID)) {
            let studyEventRef = metaData.studyEventGroup.get(studyEventGroupOID)?.studyEventRef;
            if(Array.isArray(studyEventRef)) {
                return studyEventRef[0]?.studyEventOID;
            }
        }

        return null;
    }

    const findFirstFormOID = (metaData, studyEventOID) => {
        if(metaData.studyEvent.has(studyEventOID)) {
            let formRef = metaData.studyEvent.get(studyEventOID)?.formRef;
            if(Array.isArray(formRef)) {
                return formRef[0]?.formOID;
            }
        }

        return null;
    }

    const firstStudyEventOID = isEmpty(studyEventGroupOID) == false ? findFirstStudyEventOID(metaData, studyEventGroupOID) : null;
    const firstFormOID = isEmpty(firstStudyEventOID) == false ? findFirstFormOID(metaData, firstStudyEventOID) : null;
    // console.log(firstStudyEventOID);

    //Screening Number 값 확인
    const [isScreeningNumber, isSubjectField, isAdverseEvent] = [
        !(metaDataStore.study?.basicDefinitions == null || metaDataStore.study?.basicDefinitions?.screeningNumberDef == null),
        metaData.subjectField != null,
        metaData.adverseEvent != null
    ];

    const subjectFieldDef = metaData.subjectField;

    //SubjectFieldDef 정보 유효성 확인
    const subjectFieldValidate = () => {

        const errorFields = [];
        const {
            subjectId,
            consentDate,
            birthDate,
            initial,
            sex,
            ageOID,
            visitDateOID,
            randomNoOID,
            ipCodeOID
        } = subjectFieldDef;

        //SubjectId
        if(subjectId != null) {
            const errorField = checkFieldOID(subjectId);
            if(errorField !== null) {
                errorFields.push("SubjectId - " + errorField);
            }
        }

        //ConsentDate
        if(consentDate != null) {
            const errorField = checkFieldOID(consentDate);
            if(errorField !== null) {
                errorFields.push("Consent Date - " + errorField);
            }
        }

        //BirthDate
        if(birthDate != null) {
            const errorField = checkFieldOID(birthDate);
            if(errorField !== null) {
                errorFields.push("Birth Date - " + errorField);
            }
        }

        //Initial
        if(initial != null) {
            const errorField = checkFieldOID(initial);
            if(errorField !== null) {
                errorFields.push("Initial - " + errorField);
            }
        }

        //Sex
        if(sex != null) {
            const errorField = checkFieldOID(sex);
            if(errorField !== null) {
                errorFields.push("Sex - " + errorField);
            }
        }

        //Age
        if(ageOID != null) {
            if(!metaData.item.has(ageOID)) {
                errorFields.push("Age - " + ageOID);
            }
        }

        //VisitDate
        if(visitDateOID != null) {
            if(!metaData.item.has(visitDateOID)) {
                errorFields.push("Visit Date - " + visitDateOID);
            }
        }

        //RandomNo
        if(randomNoOID != null) {
            if(!metaData.item.has(randomNoOID)) {
                errorFields.push("Random No - " + randomNoOID);
            }
        }

        //IP Code
        if(ipCodeOID != null) {
            if(!metaData.item.has(ipCodeOID)) {
                errorFields.push("IP Code - " + ipCodeOID);
            }
        }

        return [errorFields.length === 0, errorFields];
    }


    const checkFieldOID = ({studyEventOID, formOID, itemGroupOID, itemOID}) => {

        //StudyEventOID Check
        if(metaData.studyEvent.has(studyEventOID)) {
            const studyEventDef = metaData.studyEvent.get(studyEventOID);
            const formOIDs = studyEventDef.formRef.map(({formOID}) => formOID);
            if(formOIDs.includes(formOID) && metaData.form.has(formOID)) {
                const formDef = metaData.form.get(formOID);
                const itemGroupOIDs = formDef.itemGroupRef.map(({itemGroupOID}) => itemGroupOID);
                if(itemGroupOIDs.includes(itemGroupOID) && metaData.itemGroup.has(itemGroupOID)) {
                    const itemGroupDef = metaData.itemGroup.get(itemGroupOID);
                    const itemOIDs = itemGroupDef.itemRef.map(({itemOID}) => itemOID);
                    if(itemOIDs.includes(itemOID) && metaData.item.has(itemOID)) {
                        //문제 없음 - null
                        return null;
                    } else { //ItemOID 존재하지 않음.
                        return [studyEventOID, formOID, itemGroupOID, itemOID].join(" > ");
                    }
                } else { //ItemGroupOID 존재하지 않음.
                    return [studyEventOID, formOID, itemGroupOID].join(" > ");
                }
            } else { //FormOID 존재하지 않음.
                return [studyEventOID, formOID].join(" > ");
            }
        } else { //StudyEventOID 존재하지 않음.
            return studyEventOID;
        }
    }

    const [isSubjectFieldValidate, subjectFieldErrorFields] = subjectFieldValidate();

    const isSubjectIdForm = firstFormOID == null || !isSubjectField ? false : (subjectFieldDef?.subjectId?.studyEventOID === firstStudyEventOID && subjectFieldDef?.subjectId.formOID === firstFormOID);
    const isConsentDateForm = firstFormOID == null || !isSubjectField ? false : (subjectFieldDef?.consentDate?.studyEventOID === firstStudyEventOID && subjectFieldDef?.consentDate?.formOID === firstFormOID);
    const randomNumberFormat = metaDataStore?.study?.basicDefinitions?.randomNumberDef?.format;
    const useRandomization = metaDataStore.studyInfo?.useRandomization ? true : ( randomNumberFormat === "" || randomNumberFormat === null || randomNumberFormat === undefined ? false : true);
    const isRandomNumber = useRandomization && (metaDataStore.study?.basicDefinitions === null || metaDataStore.study?.basicDefinitions?.randomNumberDef === null || subjectFieldDef?.randomNoOID === null) ? false : true;

    // 2024-08-20 기준, IP Code 미사용으로 주석처리
    // const isIPCode = metaDataStore.studyInfo?.useIPManagement === true ? (subjectFieldDef?.ipCodeOID === null ? false : true) : true;
    const hasDuplicateFormSibling = metaData.duplicateSiblingFormStudyEvents.length > 0;

    const isStudyTiming = !!metaDataStore.study.metaDataVersion[0]?.studyTiming;
    const isVisitWindowField = isStudyTiming ? (!!subjectFieldDef?.visitDateOID) : true;
    const isEproSync = metaDataStore.studyInfo?.useEPRO ? Array.from(metaData.eproScheduleMap.keys()).every(oid => metaData.currentProScheduleOIDs.includes(oid)) : true;

    const isIndependentEvaluation = (metaDataStore.studyInfo?.useIndependentEvaluation) ? (metaData?.independentEvaluationDef !== null) : true;

    //배포가능여부 확인
    const isPublish = isScreeningNumber && isSubjectField && isSubjectIdForm && isSubjectFieldValidate
                                    && (metaDataStore.studyInfo.versionControlType === 'DIRECT_SELECT' || isConsentDateForm)
                                    && isRandomNumber && !hasDuplicateFormSibling
                                    && checkNotificationECS && isVisitWindowField/* && isAdverseEvent*/
                                    && isIndependentEvaluation
                                    && isEproSync;

    useEffect(() => {
        (async () => { //비동기를 동기식으로 응답 받을 때 까지 대기.

            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/studies/${metaDataStore.studyInfo.oid}/mdv/${v.oid}/patch/${metaDataStore.versionInfo.patch}/publish`, {params:{env:'DEV'}});

                //State Setting
                if(status === httpStatus.ok) {
                    setChangeLog(data?.changeLog);
                    setEmailNoticeList(data?.emailNoticeList);
                    setCheckNotificationECS([...metaData.editCheck.values()].filter(ecs => ecs.action.type == "EMAIL_NOTICE").every(
                        noticeECS => data?.emailNoticeList.some(e => e.id === noticeECS.action.emailNoticeTemplateId)));
                    setLoading(false);
                }
            } catch (error) {
                //Error Exception
                errorHandle(error);
            }

            if(isProd) {
                //Production 환경에서는 record먼저 출력되므로 바로 조회
                try {
                    //REST API Request
                    const {status, data} = await axios.get(`/api/studies/${metaDataStore.studyInfo.oid}/mdv/${v.oid}/publish-record`, {params:{env:'DEV'}});
                    //State Setting
                    if(status === httpStatus.ok) {
                        setPublishRecords(data?.records);
                        setEmailNoticeList(data?.emailNoticeList);
                        setCheckNotificationECS([...metaData.editCheck.values()].filter(ecs => ecs.action.type == "EMAIL_NOTICE").every(
                            noticeECS => data?.emailNoticeList.some(e => e.id === noticeECS.action.emailNoticeTemplateId)));
                    }
                } catch (error) {
                    //Error Exception
                    errorHandle(error);
                }
            }
        })()

    }, []);

    const onPublish = async(values) => {
        try {
            const res = await Swal.fire({
                icon: 'question',
                iconColor: 'lightskyblue',
                title: t('odm.publish.modal.title'),
                confirmButtonText: t('btn.publish'),
                cancelButtonText: t('btn.cancel'),
                showCancelButton: true,
                html:t('odm.publish.modal.html')
            });

            if(res.isConfirmed) {
                const {status, data} = await api.publishMetaData(params, values);
                if (status === httpStatus.ok) {
                    message.info(data?.message);
                    setPublishRecords(null); //Record 항목 변경에 따른 탭 변경 시 내용 갱신을 위함.
                    form.setFieldsValue({password: null, comment: null});
                }
            }
        } catch (e) {
            setPublishRecords(null);
            errorHandle(e);
        }
    }

    const validate = () => {
        let errors = new Array();
        let allOIDs = new Array();

        [...metaData.valueList.values()].map(valueList => {
            valueList.itemRef.map(itemRef => {
                allOIDs.push(itemRef.itemOID);
            });
        });
        [...metaData.studyEventGroup.values()].map(studyEventGroup => {
            studyEventGroup.studyEventRef.map(studyEventRef => {
                allOIDs.push(studyEventRef.studyEventOID);
            });
        });

        [...metaData.studyEvent.values()].map(studyEvent => {
            studyEvent.formRef.map(formRef => {
                allOIDs.push(formRef.formOID);
            });

            if(allOIDs.findIndex(oid => oid === studyEvent.oid) === -1) {
                errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.study.event.oid.not.being.used', {oid : studyEvent.oid})}</Typography.Text>);
            }
        });

        [...metaData.form.values()].map(form => {
            form.itemGroupRef.map(itemGroupRef => {
                allOIDs.push(itemGroupRef.itemGroupOID);
            });

            if(allOIDs.findIndex(oid => oid === form.oid) === -1) {
                errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.form.oid.not.being.used', {oid : form.oid})}</Typography.Text>);
            }
        });

        [...metaData.itemGroup.values()].map(itemGroup => {
            itemGroup.itemRef.map(itemRef => {
                allOIDs.push(itemRef.itemOID);
            });

            if(allOIDs.findIndex(oid => oid === itemGroup.oid) === -1) {
                errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.item.group.oid.not.being.used', {oid : itemGroup.oid})}</Typography.Text>);
            }
        });

        [...metaData.item.values()].map(item => {
            if (item.codeListRef != null && !isEmpty(item.codeListRef.codeListOID)) {
                allOIDs.push(item.codeListRef.codeListOID);
            }

            if(allOIDs.findIndex(oid => oid === item.oid) === -1) {
                errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.item.oid.not.being.used', {oid : item.oid})}</Typography.Text>);
            }
        });

        [...metaData.codeList.values()].map(codeList => {
            if(allOIDs.findIndex(oid => oid === codeList.oid) === -1) {
                errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.code.list.oid.not.being.used', {oid : codeList.oid})}</Typography.Text>);
            }
        });

        [...metaData.valueList.values()].map(valueList => {
            if(allOIDs.findIndex(oid => oid === valueList.oid) === -1) {
                errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.value.list.oid.not.being.used', {oid : valueList.oid})}</Typography.Text>);
            }
        });

        // ECS Notification - no template
        [...metaData.editCheck.values()].map(editCheck => {
            if(emailNoticeList !== null && editCheck.action.type == "EMAIL_NOTICE") {
                if(!emailNoticeList.some(e => e.id === editCheck.action.emailNoticeTemplateId)) {
                    errors.push(<Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.email.template.edit.check.oid.not.being.used', {oid :editCheck.oid})}</Typography.Text>);
                }
            }
        });

        return errors;
    }

    const onPublishFailed = (errors) => {
        message.error(errors);
    }

    const studyEventGroupColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render: (before, record) => (
                <StudyEventGroupDef studyEventGroupDef={before} studyEventRefs={before?.studyEventRef}/>
            )
        },
        {
            title: changeLog.newVersionName,
            key: 'after',
            width:'40%',
            render:(after, record) => (
                <StudyEventGroupDefDiff oldStudyEventGroupDef={record.before} newStudyEventGroupDef={record.after}/>
            )
        },
    ]


    const studyEventColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render: (before, record) => (
                <StudyEventDef studyEventDef={before} formRefs={before?.formRef}/>
            )
        },
        {
            title: changeLog.newVersionName,
            key: 'after',
            width:'40%',
            render:(text, record) => (
                <StudyEventDefDiff oldStudyEventDef={record.before} newStudyEventDef={record.after}/>
            )
        },
    ]

    const formColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render: (before, record) => (
                <FormDef formDef={before} itemGroupRefs={before?.itemGroupRef}/>
            )
        },
        {
            title: changeLog.newVersionName,
            key: 'after',
            width:'40%',
            render:(text, record) => (
                <FormDefDiff oldFormDef={record.before} newFormDef={record.after}/>
            )
        },
    ]

    const itemGroupColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(text, record) => (
                <ItemGroupDef itemGroupDef={record.before} itemRefs={record.before?.itemRef}/>
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(text, record) => (
                <ItemGroupDefDiff oldItemGroupDef={record.before} newItemGroupDef={record.after} metaData={metaData}/>
            )
        },
    ]

    const itemColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(text, record) => (
                <ItemDef itemDef={record.before} metaData={metaData}/>
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(text, record) => (
                <ItemDefDiff oldItemDef={record.before} newItemDef={record.after} metaData={metaData}/>
            )
        },
    ];

    const codeListColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(before, record) => (
                <CodeList codeList={before}/>
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(after, record) => (
                <CodeListDiff oldCodeList={record?.before} codeList={after}/>
            )
        },
    ];

    const valueListColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(before, record) => (
                <ValueList valueList={before} metaData={metaData}/>
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(after, record) => (
                <ValueListDiff before={record?.before} valueList={after} metaData={metaData}/>
            )
        },
    ];

    const editCheckColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(before, record) => (
                <EditCheck editCheck={before} referenceOIDs={record?.beforeReferenceObjectOIDs} />
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(after, record) => (
                <EditCheckDiff before={record?.before} editCheck={after}
                               beforeRefOIDs={record?.beforeReferenceObjectOIDs||[]}
                               afterRefOIDs={record?.afterReferenceObjectOIDs||[]} />
            )
        },
    ];

    const subjectStatusColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(before, record) => (
                <SubjectStatus subjectStatus={before} />
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(after, record) => (
                <SubjectStatusDiff oldSubjectStatus={record?.before} subjectStatus={after} />
            )
        },
    ]

    const saeMapColumns = [
        {
            title: 'OID',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(before, record) => (
                <SAEMapDef saeItemGroupDefs={before} />
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(after, record) => (
                <SAEMapDefDiff oldSaeItemGroupDefs={record?.before} saeItemGroupDefs={after} />
            )
        },
    ]

    const settingColumns = [
        {
            title: 'Menu',
            dataIndex: 'oid',
            key: 'oid',
            render: (oid) => <Tag color={"orange"}>{oid}</Tag>,
        },
        {
            title: changeLog.oldVersionName,
            dataIndex: 'before',
            width:'40%',
            key: 'before',
            render:(before, record) => (
                <>
                    {
                        (record?.oid === 'Field MAP') &&
                            <SubjectField subjectField={before} />
                    }
                    {
                        (record?.oid === 'AE/SAE') &&
                            <AdverseEvent adverseEvent={before} />
                    }
                    {
                        (record?.oid === 'Screening Number') &&
                            <ScreeningNumber screeningNumber={before} />
                    }
                    {
                        (record?.oid === 'Random Number') &&
                            <RandomNumber randomNubmer={before} />
                    }
                    {
                        (record?.oid === 'Visit Window') &&
                            <VisitWindow visitWindow={before} />
                    }
                    {
                        (record?.oid === "ePRO") &&
                            <ProSchedule proSchedule={before} />
                    }
                </>
            )
        },
        {
            title: changeLog.newVersionName,
            dataIndex: 'after',
            width:'40%',
            key: 'after',
            render:(after, record) => (
                <>
                    {
                        (record?.oid === 'Field MAP') &&
                            <SubjectFieldDiff oldSubjectField={record?.before} subjectField={after} />
                    }
                    {
                        (record?.oid === 'AE/SAE') &&
                            <AdverseEventDiff oldAdverseEvent={record?.before} adverseEvent={after} />
                    }
                    {
                        (record?.oid === 'Screening Number') &&
                            <ScreeningNumberDiff oldScreeningNumber={record?.before} screeningNumber={after} />
                    }
                    {
                        (record?.oid === 'Random Number') &&
                            <RandomNumberDiff oldRandomNumber={record?.before} randomNumber={after} />
                    }
                    {
                        (record?.oid === 'Visit Window') &&
                            <VisitWindowDiff oldVisitWindow={record?.before} visitWindow={after} />
                    }
                    {
                        (record?.oid === "ePRO") &&
                            <ProScheduleDiff oldProSchedule={record?.before} proSchedule={after} />
                    }
                </>
            )
        },
    ]

    const recordColumns = [
        {
            title: 'No.',
            key:'id',
            render: (id) => publishRecords?.length - publishRecords.indexOf(id)
        },
        {
            title: 'Version',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Published By',
            key: 'publishedBy',
            dataIndex:'publishedBy'
        },
        {
            title: 'Published Date',
            key: 'publishedDate',
            dataIndex:'publishedDate',
            render:(row) => dayjs(row).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: 'Result',
            key: 'result',
            dataIndex:'result',
            render:(row) => <Tag icon={<>{row==='FAIL'&&<i className='fa fa-search-plus mr-1'></i>}</>} color={row==='PASS'?'geekblue':'red'}>{row}</Tag>
        },
        {
            title: 'Comment',
            key: 'comment',
            dataIndex: 'comment'
        }
    ]

    const onClickPublishRecord = (row) => {
        return {
            onClick: (e) => {
                /* Fail일 때, 오류 메세지 모달 출력 */
                if(row.result === 'FAIL') {
                    setRecordErrorVisible(true);
                    setRecordErrorMessages(row.errorMessages);
                }
            }
        }
    }

    const onPublishChange = async (key) => {
        const v = metaDataStore.study.metaDataVersion[0];

        if(publishRecords === null && key === "publishRecord") {
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/studies/${metaDataStore.studyInfo.oid}/mdv/${v.oid}/publish-record`, {params:{env:'DEV'}});
                //State Setting
                if(status === httpStatus.ok) {
                    console.log(data?.records);
                    setPublishRecords(data?.records);
                }
            } catch (error) {
                //Error Exception
                errorHandle(error);
            }
        }
    }

    return (
        <Row gutter={[20, 0]}>
            <Col span={'14'}>
                <Card loading={loading} styles={{header: {lineHeight: 2.5}}} title={<Badge count={changeLog.changeCount} offset={[20,0]}>{t('odm.publish.change-log')}</Badge>}>
                    <Tabs type="card" size={'small'} style={{padding:'0px',margin:0}}>
                        <TabPane tab="Event Groups" key="1">
                            <Table dataSource={changeLog.studyEventGroupDefs} rowKey={'oid'} size={'small'} columns={studyEventGroupColumns}/>
                        </TabPane>
                        <TabPane tab="Events" key="2">
                            <Table dataSource={changeLog.studyEventDefs} rowKey={'oid'} size={'small'} columns={studyEventColumns}/>
                        </TabPane>
                        <TabPane tab="Forms" key="3">
                            <Table dataSource={changeLog.formDefs} rowKey={'oid'} size={'small'} columns={formColumns}/>
                        </TabPane>
                        <TabPane tab="Item Groups" key="4">
                            <Table dataSource={changeLog.itemGroupDefs} rowKey={'oid'} size={'small'} columns={itemGroupColumns}/>
                        </TabPane>
                        <TabPane tab={'Items'} key="5">
                            <Table dataSource={changeLog.itemDefs} rowKey={'oid'} size={'small'} columns={itemColumns}/>
                        </TabPane>
                        <TabPane tab="CodeLists" key="6">
                            <Table dataSource={changeLog.codeLists} rowKey={'oid'} size={'small'} columns={codeListColumns}/>
                        </TabPane>
                        <TabPane tab="ValueLists" key="7">
                            <Table dataSource={changeLog.valueLists} rowKey={'oid'} size={'small'} columns={valueListColumns}/>
                        </TabPane>
                        <TabPane tab="EditChecks" key="8">
                            <Table dataSource={changeLog.editCheckDefs} rowKey={'oid'} size={'small'} columns={editCheckColumns} />
                        </TabPane>
                        <TabPane tab="Subject Status" key="9">
                            <Table dataSource={changeLog.subjectStatusDefs} rowKey={'oid'} size={'small'} columns={subjectStatusColumns} />
                        </TabPane>
                        <TabPane tab="SAE-Map" key="10">
                            <Table dataSource={changeLog.saeMapDefs} rowKey={'oid'} size={'small'} columns={saeMapColumns} />
                        </TabPane>
                        <TabPane tab="Setting" key="11">
                            <Table dataSource={changeLog.settingDefs} rowKey={'oid'} size={'small'} columns={settingColumns} />
                        </TabPane>
                        {/*<TabPane tab="Edit Checks" key="8">*/}
                        {/*    Content of Tab Pane 3*/}
                        {/*</TabPane>*/}
                    </Tabs>
                </Card>
            </Col>
            <Col span={'10'}>
                <StickyDiv style={{margin:0}}>
                    <Tabs type={'card'} defaultActiveKey={"publish"} onChange={onPublishChange}>
                        {
                            !isProd &&
                            <TabPane tab={t('odm.publish.tab.publish')} key={"publish"}>
                                <Form onFinish={onPublish} onFinishFailed={onPublishFailed} form={form} layout={'vertical'}>
                                    <Row gutter={[20, 0]}>
                                        <Col span={'24'}>
                                            {/*<Typography.Text type={"success"}>* DEV 환경에 아래의 버전을 배포 합니다.</Typography.Text>*/}
                                            <Descriptions column={1}
                                                          bordered
                                                // title="Metadata Version"
                                                          size={"small"}
                                            >
                                                <Descriptions.Item label={t('odm.publish.description.version')}>{metaDataStore.study.metaDataVersion[0].name}</Descriptions.Item>
                                                <Descriptions.Item label={t('odm.publish.description.description')}>{metaDataStore.study.metaDataVersion[0]?.description}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                    {
                                        isPublish &&
                                        <Row gutter={[20, 0]}>
                                            <Col span={'24'}>
                                                <FormTextArea required={false} name={"comment"} label={"Comment"}/>
                                            </Col>
                                        </Row>
                                    }
                                    {isPublish &&
                                        <Button type={"primary"} disabled={changeLog.changeCount === 0 || !protocol} htmlType={'submit'}>
                                            <i className={'fa fa-send-o mr-2'}/>Publish
                                        </Button>
                                    }
                                    <Row gutter={[20, 0]} style={{marginTop:15}}>
                                        <Col span={'24'}>
                                            <Card>
                                                <Space direction={"vertical"} style={{fontSize:12}}>
                                                    {isScreeningNumber === false &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i>{t('odm.pls.verify.screening.num.config')}</Typography.Text>
                                                    }
                                                    {isSubjectField === false &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i>{t('odm.pls.verify.subject.filed.mapping.config')}</Typography.Text>
                                                    }
                                                    {(isSubjectIdForm == false || (metaDataStore.studyInfo.versionControlType !== 'DIRECT_SELECT' && isConsentDateForm == false)) &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {isSubjectIdForm == false ? t('odm.subject.num') : ''} {t('odm.consent.sign.date.info.is.not.set')}</Typography.Text>
                                                    }
                                                    {isSubjectFieldValidate === false &&
                                                        <>
                                                        {
                                                            subjectFieldErrorFields.map((errorField, idx) => (
                                                                <Typography.Text key={`ErrorField_${idx}`} type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('message.error.invalid.subject-field', {errorField})}</Typography.Text>
                                                            ))
                                                        }
                                                        </>
                                                    }
                                                    {isEproSync === false &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i>{t('odm.pls.verify.sync.epro-schedule')}</Typography.Text>
                                                    }
                                                    {(isRandomNumber === false) &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.pls.verify.random.num.mapping.config')}</Typography.Text>
                                                    }
                                                    {/*{(isIPCode === false) &&*/}
                                                    {/*    <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.pls.verify.ip.code.mapping.config')}</Typography.Text>*/}
                                                    {/*}*/}
                                                    {(isIndependentEvaluation === false) &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.ie-setting.not.saved')}</Typography.Text>
                                                    }
                                                    {protocol !== true &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.soa.config.is.required')}</Typography.Text>
                                                    }
                                                    {hasDuplicateFormSibling &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> [{metaData.duplicateSiblingFormStudyEvents.join(',')}] {t('odm.more.two.form.sibling.config.form.study.event')}</Typography.Text>
                                                    }
                                                    {isAdverseEvent === false &&
                                                        <Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {t('odm.pls.verify.ae.mapping.config')}</Typography.Text>
                                                    }
                                                    {(checkNotificationECS !== null && checkNotificationECS === false) &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.pls.verify.email.template.config.notification.ecs')}</Typography.Text>
                                                    }
                                                    {isVisitWindowField === false &&
                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.pls.verify.visit.date.mapping.config')}</Typography.Text>
                                                    }
                                                    {protocol === true &&
                                                    v.protocol.studyEventGroupRef.map(ref => (
                                                        <React.Fragment key={ref.studyEventGroupOID}>
                                                            {
                                                                metaData.studyEventGroup.has(ref.studyEventGroupOID) === false &&
                                                                <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.event.group.oid.not.exist', {oid : ref.studyEventGroupOID})}</Typography.Text>
                                                            }
                                                            {
                                                                metaData.studyEventGroup.has(ref.studyEventGroupOID) === true &&
                                                                metaData.studyEventGroup.get(ref.studyEventGroupOID).studyEventRef.map(studyEventRef => (
                                                                    <React.Fragment key={studyEventRef.studyEventOID}>
                                                                        {metaData.studyEvent.has(studyEventRef.studyEventOID) === false &&
                                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.study.event.group.oid.study.event.oid.not.exist', {studyEventGroupOID : ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID})}</Typography.Text>
                                                                        }
                                                                        {metaData.studyEvent.has(studyEventRef.studyEventOID) === true &&
                                                                            metaData.studyEvent.get(studyEventRef.studyEventOID).formRef.map(formRef => (
                                                                            <React.Fragment key={formRef.formOID}>
                                                                                {metaData.form.has(formRef.formOID) === false &&
                                                                                <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i>{t('odm.study.event.group.oid.study.event.oid.form.oid.not.exist', {studyEventGroupOID : ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID, formOID :formRef.formOID})}</Typography.Text>
                                                                                }
                                                                                {metaData.form.has(formRef.formOID) === true &&
                                                                                metaData.form.get(formRef.formOID).itemGroupRef.map(itemGroupRef => (
                                                                                    <React.Fragment key={itemGroupRef.itemGroupOID}>
                                                                                        {/*<>{metaData.itemGroup.get(itemGroupRef.itemGroupOID).repeating}</>*/}
                                                                                        {metaData.itemGroup.has(itemGroupRef.itemGroupOID) === false &&
                                                                                        <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i> {t('odm.study.event.group.oid.study.event.oid.form.oid.item.group.oid.not.exist', {studyEventGroupOID : ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID, formOID : formRef.formOID, itemGroupOID : itemGroupRef.itemGroupOID})}</Typography.Text>
                                                                                        }
                                                                                        {metaData.itemGroup.has(itemGroupRef.itemGroupOID) === true &&
                                                                                        metaData.itemGroup.get(itemGroupRef.itemGroupOID).repeating === 'STATIC' &&
                                                                                        <>
                                                                                            {
                                                                                                metaData.itemGroup.get(itemGroupRef.itemGroupOID).itemRef.findIndex(itemRef => itemRef.itemOID.endsWith('TESTCD')) === -1 &&
                                                                                                <Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i>{t('odm.study.event.group.oid.study.event.oid.form.oid.item.group.oid.not.contain.testcd.item', {studyEventGroupOID : ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID, formOID : formRef.formOID, itemGroupOID : itemGroupRef.itemGroupOID})}</Typography.Text>
                                                                                            }
                                                                                            {
                                                                                                metaData.itemGroup.get(itemGroupRef.itemGroupOID).itemRef.findIndex(itemRef => itemRef.itemOID.endsWith('TESTCD') && isEmpty(itemRef.valueListOID) === false) === -1 &&

                                                                                                <Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i>{t('odm.study.event.group.oid.study.event.oid.form.oid.item.group.oid.not.have.value.list.config.testcd', {studyEventGroupOID : ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID, formOID : formRef.formOID, itemGroupOID : itemGroupRef.itemGroupOID})}</Typography.Text>
                                                                                            }
                                                                                            {/*{*/}
                                                                                            {/*    metaData.itemGroup.get(itemGroupRef.itemGroupOID).itemRef.findIndex(itemRef => itemRef.itemOID.endsWith('ORRES')) === -1 &&*/}
                                                                                            {/*    <Typography.Text type="warning"><i className={'fa fa-exclamation-circle'}></i> {ref.studyEventGroupOID} &gt; {studyEventRef.studyEventOID} &gt; {formRef.formOID} &gt; ItemGroup [{itemGroupRef.itemGroupOID}]에 --ORRES 아이템이 존재하지 않습니다.</Typography.Text>*/}
                                                                                            {/*}*/}
                                                                                            {
                                                                                                metaData.itemGroup.get(itemGroupRef.itemGroupOID).itemRef.map(itemRef => (
                                                                                                    <React.Fragment key={itemRef.itemOID}>
                                                                                                        {
                                                                                                            metaData.item.has(itemRef.itemOID) === false &&
                                                                                                            <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i>{t('odm.study.event.group.oid.study.event.oid.form.oid.item.group.oid.item.oid.not.exist', {studyEventGroupOID: ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID, formOID : formRef.formOID, itemGroupOID : itemGroupRef.itemGroupOID, itemOID : itemRef.itemOID})}</Typography.Text>
                                                                                                        }
                                                                                                        {
                                                                                                            metaData.item.has(itemRef.itemOID) === true &&
                                                                                                            metaData.item.get(itemRef.itemOID).codeListRef !== null &&
                                                                                                            !isEmpty(metaData.item.get(itemRef.itemOID).codeListRef.codeListOID) &&
                                                                                                            metaData.codeList.has(metaData.item.get(itemRef.itemOID).codeListRef.codeListOID) === false &&
                                                                                                            <Typography.Text type="danger"><i className={'fa fa-exclamation-circle'}></i>{t('odm.study.event.group.oid.study.event.oid.form.oid.item.group.oid.item.ref.oid.code.list.oid.not.exist', {studyEventGroupOID : ref.studyEventGroupOID, studyEventOID : studyEventRef.studyEventOID, formOID : formRef.formOID, itemGroupOID : itemGroupRef.itemGroupOID, itemOID : itemRef.itemOID, codeListOID : metaData.item.get(itemRef.itemOID).codeListRef.codeListOID})}</Typography.Text>
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                                }
                                                                            </React.Fragment>
                                                                        ))
                                                                        }
                                                                    </React.Fragment>
                                                                ))

                                                            }
                                                        </React.Fragment>
                                                    ))
                                                        //
                                                    }

                                                    {
                                                        validate()
                                                    }
                                                </Space>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                        }
                        <TabPane tab={t('odm.publish.tab.publish-record')} key={"publishRecord"}>

                            <PublishRecordErrorModal visible={recordErrorVisible}
                                                     setVisible={setRecordErrorVisible}
                                                     messages={recordErrorMessages}
                            />

                            <Table columns={recordColumns} dataSource={publishRecords} rowKey={'id'} onRow={onClickPublishRecord}
                                   size={'small'} pagination={{pageSize:5, position:['topRight', 'bottomRight']}} loading={publishRecords === null} />
                        </TabPane>
                    </Tabs>
                </StickyDiv>
            </Col>
        </Row>
    );
}

export default withTranslation()(PublishContainer);
