import React from 'react'
import style from './style.module.scss'
import UserMenu from "./UserMenu";
import SystemTimer from "./Timer";
import SubMenu from "./SubMenu";
import {Tag} from "antd";
import LanguageSwitcher from "./LanguageSwitcher";
import Notice from "./Notice";

const TopBar = ({user, edcStudyInfo}) => {

    return (
        <div className={style.topbar}>
            <SubMenu edcStudyInfo={edcStudyInfo} />
            <div className="mr-auto mr-md-1 text-right">
                {
                    edcStudyInfo !== null &&
                    <>
                        <Tag icon={'P/N : '} style={{maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {edcStudyInfo?.study?.protocolName}
                        </Tag>
                        <Tag icon={'S/N : '} style={{maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {edcStudyInfo?.study?.name}
                        </Tag>
                    </>
                }
            </div>
            <div className="mr-4 d-none d-md-block"/>
            <div className="mr-auto d-xl-block d-none"/>
            <div className="mr-3 d-none d-sm-block ml-auto"/>
            <div className="mr-3 d-none d-sm-block" >
                <Notice />
            </div>
            <div className="mr-3 d-none d-sm-block" >
                <LanguageSwitcher />
            </div>
            <div className="mr-3 d-none d-sm-block" style={{width:'6rem'}}>
                <SystemTimer user={user}/>
            </div>
            <div>
                <UserMenu user={user}/>
            </div>
        </div>
    )
}

export default TopBar
