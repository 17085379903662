import DefaultLoading from "../../DefaultLoading";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Select, Switch, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {RightOutlined} from "@ant-design/icons";
import _ from 'lodash';
import dayjs from 'dayjs';
import FormInputNumber from "../../odm/ant-form/FormInputNumber";
import {useTranslation} from "react-i18next";

const entryUserGroups = [
    {value:'FIRST', label:'First Entry Group'},
    {value:'SECOND', label:'Second Entry Group'},
];

const ManageUsersEdit = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //query-string이 존재하면 studyID에 해당하는 값 불러오기.
    const params = useParams();
    const query = queryString.parse(location.search);
    const [edcStudyRoles, setEdcStudyRoles] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [envList, setEnvList] = useState(null);
    const [studyInfo, setStudyInfo] = useState(null);
    const [studySites, setStudySites] = useState(null);
    const [studyUser, setStudyUser] = useState(null);
    const [independentEvaluator, setIndependentEvaluator] = useState(false);

    const tagPalette = ['geekblue', 'gold', 'green', 'cyan', 'magenta'];
    const [form] = Form.useForm();

    //Button Loading
    const [loading, setLoading] = useState(false);


    useEffect( () => {
        (async () => {
            //GET Request
            try {
                //REST API Request
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/users/edit`, {params: query});

                //State Setting
                if(status === httpStatus.ok) {
                    setEdcStudyRoles(data?.edcStudyRoles);

                    if(data?.studyUser?.selectedRoles != null) {
                        setSelectedRoles(data?.edcStudyRoles.filter(s => data?.studyUser?.selectedRoles.includes(s.id)));
                    }

                    setEnvList(data?.envList);
                    setStudySites(data?.studySites);
                    setStudyInfo(data?.studyInfo);

                    //Mutiple Select Processing
                    if(data?.studyUser != null) {
                        data.studyUser.selectedRoles = data.studyUser.selectedRoles!==null?data.studyUser.selectedRoles:[];
                    }

                    //selectedRolesInfo가 null이면 빈 객체값 생성
                    if(data?.studyUser?.selectedRolesInfo === null) {
                        data.studyUser.selectedRolesInfo = {};
                    }

                    setStudyUser({
                        ...data?.studyUser,
                        expiredDate: data?.studyUser.expiredDate!=null?dayjs(data?.studyUser.expiredDate, 'YYYY-MM-DD'):null,
                    });

                    //해당 StudyUser가 독립적 평가자인지에 대한 옵션 설정
                    setIndependentEvaluator(data?.studyUser?.independentEvaluator);
                }
            } catch (error) {
                errorHandle(error, navigate, `/studies/${params.sid}/users`);
            }
        })()
    }, [])

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const body = {
                ...values,
                selectedRolesInfo:{
                    ...studyUser.selectedRolesInfo,
                },
                expiredDate: values?.expiredDate!=null?dayjs(values?.expiredDate).format('YYYY-MM-DD'):null
            }

            const {status, data} = await axios({
                url: `/api/builder/studies/${params.sid}/users/edit`,
                data: body,
                method: `post`
            })

            if(status === httpStatus.ok) {
                message.success(data?.message);
                navigate(`/studies/${params.sid}/users`);
            }
        } catch(e) {
            errorHandle(e);
        }

        setLoading(false);
    }

    const onFinishFailed = (errors) => {
        console.log(errors);
    }

    const onAllSwitch = (roleId, value) => {
        const rolesInfo = _.cloneDeep(studyUser.selectedRolesInfo);
        rolesInfo[`${roleId}`].siteAll = value;
        if(value) {rolesInfo[`${roleId}`].selectedSites = []; }
        setStudyUser({...studyUser, selectedRolesInfo:rolesInfo});
        form.setFieldsValue({selectedRolesInfo:rolesInfo});
    }

    const onSkipSwitch = (roleId, value) => {
        const rolesInfo = _.cloneDeep(studyUser.selectedRolesInfo);
        rolesInfo[`${roleId}`].learningSkip = value;
        setStudyUser({...studyUser, selectedRolesInfo:rolesInfo});
        form.setFieldsValue({selectedRolesInfo:rolesInfo});
    }

    const onRolesChange = (values) => {
        if(selectedRoles?.length < values?.length) {
            const newValue = values.find(value => selectedRoles.every(role => role.id !== value)); //새로 추가된 내용

            //새로 추가된 항목 추가
            if(newValue != null) {
                //저장전 정보를 가지고 있으면 새로 추가할 때 세팅한다.
                const savedSelectedRoleInfo = form.getFieldValue(['selectedRolesInfo', newValue])??{};

                studyUser.selectedRolesInfo[`${newValue}`] = {
                    siteAll: false,
                    learningSkip: false,
                    selectedSites: [],
                    selectedEnvs: [],
                    ...savedSelectedRoleInfo
                };
            }
        }
        else if(selectedRoles?.length > values?.length) {
            const removeValue = selectedRoles.find(role => values.every(value => value !== role.id)); //삭제될 내용
            //삭제 될 항목 삭제
            if(removeValue != null) { delete studyUser.selectedRolesInfo[`${removeValue}`]; }
        }

        setSelectedRoles(_.cloneDeep(edcStudyRoles.filter(role => values.includes(role.id)))); //선택된 Role 지정
        setStudyUser({...studyUser});
        form.setFieldsValue({selectedRolesInfo:{...studyUser.selectedRolesInfo}})
    }

    const onRoleSitesChange = (roleId, values) => {
        const selectedSites = _.cloneDeep(form.getFieldValue(['selectedRolesInfo', `${roleId}`, 'selectedSites']));
        studyUser.selectedRolesInfo[`${roleId}`].selectedSites = selectedSites;
        setStudyUser({...studyUser});
    }

    const onRoleEnvsChange = (roleId, values) => {
        const selectedSites = _.cloneDeep(form.getFieldValue(['selectedRolesInfo', `${roleId}`, 'selectedEnvs']));
        studyUser.selectedRolesInfo[`${roleId}`].selectedEnvs = selectedSites;
        setStudyUser({...studyUser});
    }

    const onChangeIE = (value) => {
        setIndependentEvaluator(value);
    }

    return (
        <>
            {
                studyUser === null &&
                <DefaultLoading/>
            }
            {
                studyUser !== null &&
                <DefaultCard>
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'} />User</h3>
                    </CardHeader>
                    <Form layout="vertical"
                          initialValues={studyUser}
                          form={form}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}>
                        <CardBody>
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                <Form.Item hidden={true} name={'id'}>
                                    <Input readOnly />
                                </Form.Item>
                                <Col span={'12'} >
                                    <Form.Item name={['user', 'id']} hidden={true}><Input /></Form.Item>
                                    <Form.Item name={['user', 'username']} label={t('column.user.email')} required>
                                        <Input readOnly />
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    {
                                        studyInfo?.entryType?.key === 'DDE' &&
                                        <Form.Item name={'entryUserGroup'} label={<>t('column.user.group')(<span className="text-info font-size-12"><i className="fa fa-exclamation-circle"></i> t('studies.only.used.double.data.entry.task')</span>)</>}>
                                            <Select allowClear={true} options={entryUserGroups}></Select>
                                        </Form.Item>
                                    }
                                    {
                                        studyInfo?.entryType?.key !== 'DDE' && studyInfo.useIndependentEvaluation &&
                                        <Row>
                                            <Col span={6}>
                                                <Form.Item name={'independentEvaluator'} label={'독립적 평가자 여부'} valuePropName={'checked'}>
                                                    <Switch title={'독립적 평가자 여부'} onChange={onChangeIE} />
                                                </Form.Item>
                                            </Col>
                                            {
                                                independentEvaluator &&
                                                <Col span={18}>
                                                    <Form.Item name={'evaluatorNo'} label={'평가자 번호'} rules={[
                                                        {required: true, message:'필수 입력 항목입니다.'},
                                                    ]}>
                                                        <InputNumber min={1} max={3} style={{width:'100%'}} />
                                                    </Form.Item>
                                                </Col>
                                            }
                                        </Row>
                                    }
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'selectedRoles'} label={t('column.role')} required
                                               rules={[{
                                                   required: true,
                                                   message: t('studies.pls.select.role')
                                               }]}
                                    >
                                        <Select mode={'multiple'} onChange={onRolesChange}>
                                            {
                                                edcStudyRoles.map((edcStudyRole, index) => (
                                                    <Select.Option key={index} value={edcStudyRole?.id}>
                                                        {edcStudyRole.displayName}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={'12'}>
                                    <Form.Item name={'expiredDate'} label={t('column.expiration.date')}>
                                        <DatePicker className={'width-100p'} disabledDate={(current) => (current && current < dayjs().endOf('day'))}  />
                                    </Form.Item>
                                </Col>
                                <Col span={'24'}>
                                    {
                                        (studyUser !== null && selectedRoles !== null) && (
                                            selectedRoles.map((role, index, array) => (
                                                <React.Fragment key={role.id}>
                                                    <Form.Item name={['selectedRolesInfo', `${role.id}`, 'selectedSites']}
                                                               label={
                                                                   <>
                                                                       <Tag color={tagPalette[index%5]}>
                                                                           {role.displayName}
                                                                       </Tag>
                                                                       <Switch className={'mr-2'} title={'ALL'} style={{width:'5.5rem'}}
                                                                               checked={studyUser.selectedRolesInfo[`${role.id}`]?.siteAll}
                                                                               onClick={(value) => onAllSwitch(role.id, value)}
                                                                               checkedChildren={'ALL'} unCheckedChildren={'Not ALL'} />
                                                                       <Switch title={'Learning Skip'} style={{width:'5.5rem'}}
                                                                               checked={studyUser.selectedRolesInfo[`${role.id}`]?.learningSkip}
                                                                               onClick={(value) => onSkipSwitch(role.id, value)}
                                                                               checkedChildren={'Skip'} unCheckedChildren={'No Skip'} />
                                                                   </>
                                                               }
                                                               required={!studyUser.selectedRolesInfo[`${role.id}`].siteAll}
                                                               rules={[{
                                                                   required: !studyUser.selectedRolesInfo[`${role.id}`].siteAll,
                                                                   message: t('studies.pls.select.site')
                                                               }]} >
                                                        <Select mode={'multiple'} placeholder={'Role Sites'}
                                                                disabled={studyUser.selectedRolesInfo[`${role.id}`]?.siteAll}
                                                                onChange={(values) => onRoleSitesChange(role.id, values)}
                                                        >
                                                            {
                                                                studySites.map((studySite, index) => (
                                                                    <Select.Option key={studySite?.id} value={studySite?.id}>
                                                                        {`${studySite?.name}-${studySite?.oid}`}
                                                                    </Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name={['selectedRolesInfo', `${role.id}`, 'selectedEnvs']}
                                                               rules={[{required:true, message: `[${role.displayName}]` + t('studies.pls.select.env.which.role')}]}
                                                    >
                                                        <Select mode={'multiple'} style={{width:'100%'}} placeholder={'Role Environment - [DEV/UAT/PROD]'}
                                                                onChange={(values) => onRoleEnvsChange(role.id, values)}>
                                                            {
                                                                envList.map((env, index) => (
                                                                    <Select.Option key={`${env?.key}${index}`} value={env?.key}>
                                                                        {`[${env?.key}] ${env.label}`}
                                                                    </Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </React.Fragment>
                                            ))
                                        )
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button type={'default'} onClick={() => {
                                navigate(`/studies/${params.sid}/users`);
                            }} className={'mr-3'}> Cancel </Button>
                            <Button loading={loading} type={'primary'} htmlType={'submit'}> Submit </Button>
                        </CardFooter>
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default ManageUsersEdit;
