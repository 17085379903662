import {CardBody, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Button, Card, Checkbox, Col, Form, Input, message, Row, Select, Mentions, InputNumber} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpStatus} from "../../../../lib/createRequestSaga";
import {useNavigate, useParams} from "react-router-dom";
import DefaultLoading from "../../DefaultLoading";
import {RightOutlined} from "@ant-design/icons";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {isEmpty} from "../../../../lib/StringUtils";
import classNames from "classnames";
import styles from "../../odm/style.module.scss";
import {useTranslation} from "react-i18next";
const { Option } = Mentions;

const maxLength = 10;
const maxNum = 9;
const options = [];
for (let i = 0; i <= maxNum; i++) {
    console.log(i);
    if (i === 0) {
        options.push({
            // key: i,
            value: i.toString(),
            label: '...'
        });
    } else {
        if (i !== 1) {
            options.push({
                // key: i,
                value: i.toString(),
                label: i.toString()
            });
        }
    }
}

const BasicDefinition = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [basicDefinition, setBasicDefinition] = useState(null);
    const [form] = Form.useForm();
    const {t} = useTranslation();

    //Button Loading
    const [loading, setLoading] = useState(false);

    // Screening Number Setting
    const [maxNumber, setMaxNumber] = useState(999999999);

    // Randomization Setting
    const [randomNumberFormat, setRandomNumberFormat] = useState('');
    const [useRandomization, setUseRandomization] = useState(false);
    const [disableView, setDisableView] = useState(false);

    /**
     * MaxNumber Setting - 스크리닝 넘버의 initialNumber에 대한 최대 입력수치를 지정한다.
     * @param length
     */
    const settingMaxNumber = (length) => {
        let max = '';
        for(let i=0; i<length; i++) { max+='9';}
        setMaxNumber(parseInt(max));
    }

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/basic-definition`);
                if (status === httpStatus.ok) {
                    //Randomization 사용여부 확인
                    setUseRandomization(data?.useRandomization);

                    const screeningNumberDef = data?.basicDefinition?.screeningNumberDef??{};
                    const randomNumberDef = data?.basicDefinition?.randomNumberDef??{};
                    const seq = RegExp(/\${Seq}/g);

                    if(seq.test(randomNumberDef.format)){
                        setRandomNumberFormat(randomNumberDef?.format.replace(seq, generateNumber(randomNumberDef)));
                    }

                    setBasicDefinition({...data?.basicDefinition,
                        screeningNumberDef: {
                            ...screeningNumberDef,
                            siteCode:screeningNumberDef.siteCode?'true':'false',
                            length:screeningNumberDef?.length??2,
                            initialNumber:screeningNumberDef?.initialNumber<=0?1:screeningNumberDef.initialNumber
                        },
                        randomNumberDef:randomNumberDef
                    });

                    settingMaxNumber(screeningNumberDef?.length??2);
                }

                // reset 안 된 data가 있을 경우 비활성화
                const getRandomizationViewOption = await axios.get(`/api/builder/studies/${params.sid}/stratification/resetInfo`);
                if(getRandomizationViewOption?.status === 200){
                    const resetInfoCount = getRandomizationViewOption?.data?.resetInfoCount;
                    if(resetInfoCount > 0) setDisableView(true);
                }
            } catch(e) {
                errorHandle(e, navigate);
            }
        })();
    }, []);

    //maxNumber 수치 변경 시, maxNumber보다 initialNumber값이 크면 조정
    useEffect(() => {
        const initNumber = form.getFieldValue('initialNumber');
        if(initNumber > maxNumber) {
            setBasicDefinition({
                ...basicDefinition,
                screeningNumberDef: {
                    ...basicDefinition.screeningNumberDef,
                    initialNumber:maxNumber
                }
            });
            form.setFieldsValue({screeningNumberDef:{initialNumber:maxNumber}});
        }
    }, [maxNumber])

    /**
     * ScreeningNumber의 속성을 이용하여, Sequence항목에 대한 값이 어떻게 지정될 지 설정함
     * @param length
     * @param leftPadWithZero
     * @param initialNumber
     * @param autoGenerated
     * @returns {string}
     */
    const generateNumber = ({length, leftPadWithZero, initialNumber, autoGenerated}) => {
        let strNumber = '1';
        if(autoGenerated) { strNumber = `${initialNumber}`; }

        if(leftPadWithZero && length > strNumber?.length) {
            const repeat = length - strNumber?.length;
            for(let i=0; i<repeat; i++) {
                strNumber = '0' + strNumber;
            }
        }

        return strNumber;
    };

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const response = await axios.post(`/api/builder/studies/${params.sid}/basic-definition`, values);

            if(response.status === httpStatus.ok) {
                const {data} = response;
                message.success(data.message);
            }

        } catch (e) {
            errorHandle(e);
        }

        setLoading(false);
    }

    const onFinishFailed = (e) => {
        console.log(e);
    }

    /**
     * Data Change시, Preview 내용 변경 표시.
     * @param name
     */
    const onChangeData = (name) => {
        let value = form.getFieldValue(["screeningNumberDef", name])||"";

        if(name === 'prefix' || name === 'suffix') {
            value = value.toUpperCase();
        }

        console.log(name, value);

        setBasicDefinition({
            ...basicDefinition,
            screeningNumberDef:{
                ...basicDefinition.screeningNumberDef,
                [name]: value
            }
        });

        form.setFieldsValue({screeningNumberDef:{[name]:value}});

        //Length 설정 시, initial Number의 max 수치값 조정
        if(name === 'length') {
            settingMaxNumber(value);
        }
    }

    const ScreeningNumberPreview = () => {
        const screeningNumber = basicDefinition.screeningNumberDef;

        return (
            <h3>
                <span className={'badge badge-info mr-1'}>{screeningNumber?.prefix?.length > maxLength ? screeningNumber.prefix.substring(0,maxLength) : screeningNumber.prefix}</span>
                <span className={'badge badge-default mr-1'}>{screeningNumber?.siteCode === 'true' ? '999' : ''}</span>
                <span className={'badge badge-info mr-1'}>{screeningNumber?.suffix?.length > maxLength ? screeningNumber.suffix.substring(0,maxLength) : screeningNumber.suffix}</span>
                <span className={'badge badge-default mr-1'}>{generateNumber(screeningNumber)}</span>
            </h3>
        )
    };

    const RandomNumberPreview = () => {
        const randomNumber = basicDefinition?.randomNumberDef;

        const seq = RegExp(/\${Seq}/g);
        let format = '';

        if(seq.test(randomNumber.format)){
            format = randomNumber?.format.replace(seq, generateNumber(randomNumber));
        }
        return(
            <>
                <h3>
                    <span className={'badge badge-success mr-1'}>{format}</span>
                </h3>
            </>
        )
    };

    const getRandomOptions = () => (
        <>
            <Option value={'{SiteCode}'}>SiteCode</Option>
            <Option value={'{Stratification}'}>Stratification</Option>
            <Option value={'{Seq}'}>Seq</Option>
        </>
    );

    const checkMention = async (_, value) => {
        if(isEmpty(value) && useRandomization){
            return Promise.reject(new Error(t('studies.random.number.is.required')));
        }

        if(!isEmpty(value)){
            const siteCode = RegExp(/\${SiteCode}/g);
            const stratification = RegExp(/\${Stratification}/g);
            const seq = RegExp(/\${Seq}/g);
            const test = /[${} ]/g;

            if(!seq.test(value)){
                return Promise.reject(new Error(t('studies.seq.is.required')));
            }
            const resultText = value.replaceAll(siteCode, '').replaceAll(stratification, '').replaceAll(seq, '');

            if(test.test(resultText)){
                return Promise.reject(new Error(t('studies.cannot.input.outside.specified.format')));
            }
        }

    };

    const onChangeRandom = (name) => {

        let value = form.getFieldValue(["randomNumberDef", name])||"";
        setBasicDefinition({
            ...basicDefinition,
            randomNumberDef:{
                ...basicDefinition.randomNumberDef,
                [name]: value
            }
        });
        form.setFieldsValue({randomNumberDef:{[name]:value}});

    }
    return (
        <>
            {
                (basicDefinition === null) &&
                <DefaultLoading />
            }
            {
                (basicDefinition !== null) &&
                <DefaultCard className="col-xs-6">
                    <CardHeader>
                        <h3><RightOutlined className={'mr-2'} />Basic Definition</h3>
                    </CardHeader>
                    <CardBody>
                        <Form layout="vertical"
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                              initialValues={basicDefinition}
                              form={form}>

                            <Card title={'Screening Number'}>
                                <Row gutter={[20,0]}>
                                    <Col span={3}>
                                        <Form.Item name={['screeningNumberDef', 'prefix']} label={'Prefix'}
                                                   rules={[{ max:maxLength, message: t('studies.maximum.characters', {maxLength : maxLength})}]}>
                                            <Input onChange={() => onChangeData('prefix')} maxLength={maxLength} placeholder={'Prefix'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['screeningNumberDef', 'siteCode']} label={'Site Code (ex:999)'}>
                                            <Select onChange={() => onChangeData('siteCode')}>
                                                <Select.Option key={'0'} value={'false'}>
                                                    Not Used
                                                </Select.Option>
                                                <Select.Option key={'1'} value={'true'}>
                                                    Use
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['screeningNumberDef', 'suffix']} label={'Suffix'}
                                                   rules={[{ max:maxLength, message: t('studies.maximum.characters', {maxLength: maxLength})}]}>
                                            <Input onChange={() => onChangeData('suffix')} maxLength={maxLength} placeholder={'Suffix'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item name={['screeningNumberDef', 'length']} label={'Number Length'} required={false}
                                                   rules={[{ required:true, message: t('message.required.item') }]}>
                                            <Select options={options} onChange={() => onChangeData('length')} />
                                        </Form.Item>
                                    </Col>
                                    {
                                        basicDefinition?.screeningNumberDef?.autoGenerated &&
                                        <Col span={3}>
                                            <Form.Item name={['screeningNumberDef', 'initialNumber']} label={'Initial Number'} required={false}
                                                       rules={[{ required:true, message: t('message.required.item') }]}>
                                                <InputNumber placeholder={'Initial Number'} min={1} max={maxNumber} style={{width:'100%'}}
                                                             onChange={() => onChangeData('initialNumber')} />
                                            </Form.Item>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <ScreeningNumberPreview />
                                    </Col>
                                </Row>
                                <Row gutter={[20, 0]}>
                                    <Col span={4}>
                                        <Form.Item name={['screeningNumberDef', 'autoGenerated']} valuePropName={'checked'}>
                                            <Checkbox onChange={() => onChangeData('autoGenerated')}>Auto-generated</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name={['screeningNumberDef', 'leftPadWithZero']} valuePropName={'checked'}>
                                            <Checkbox onChange={() => onChangeData('leftPadWithZero')}>Left pad string with zeros</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>

                            {/* RANDOM 작업 영역 */}
                            {
                                <Card title={'Random Number'}  className={classNames('mt-3 mb-3', {[`${styles.blockingArea}`]: disableView})}>
                                    <Row gutter={[20,0]}>
                                        <Col span={5}>
                                            <Form.Item name={["randomNumberDef", "format"]}
                                                       label={'Random Number Format'}
                                                       rules={[{validator: checkMention,},]}
                                            >
                                                <Mentions
                                                    placeholder="Random Number Format"
                                                    prefix={'$'}
                                                    split={''}
                                                    onChange={() => onChangeRandom('format')}
                                                >
                                                    {getRandomOptions()}
                                                </Mentions>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item name={['randomNumberDef', 'length']} label={'Sequence Length'} required={useRandomization}
                                                       rules={[{ required:useRandomization, message: t('message.required.item')}]}>
                                                <Select options={options} onChange={() => onChangeRandom('length')} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item className='ml-3' name={["randomNumberDef", "leftPadWithZero"]} valuePropName={'checked'} label={' '}>
                                                <Checkbox onChange={() => onChangeRandom('leftPadWithZero')}>Left pad string with zeros</Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <RandomNumberPreview />
                                        </Col>
                                    </Row>
                                </Card>
                            }
                            <Button loading={loading} type={'primary'} shape={'round'} htmlType={'submit'}>Save</Button>
                        </Form>
                    </CardBody>
                </DefaultCard>
            }
        </>
    )
}

export default BasicDefinition
